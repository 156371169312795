<template>
    <header class="page-title">
        <h1><i class="bi bi-bar-chart-fill"></i> レポート - ステータス別案件数</h1>
    </header>

    <section class="section">
        <div class="row">
            <div class="col-11">
                <label class="form-label">事業部</label>
                <form-select-search-multiple
                    v-model="condition.department_ids"
                    :options="options_department"
                >
                </form-select-search-multiple>
            </div>
            <div class="col-13">
                <label class="form-label">規格</label>
                <form-select-search-multiple
                    v-model="condition.standards"
                    :options="Standard.options()"
                >
                </form-select-search-multiple>
            </div>
            <div class="col-6 d-flex align-items-end">
            <button @click="search()" :disabled="loading_count > 0" class="btn btn-primary">表示</button>
            </div>
        </div>
    </section>

    <section class="section">
        <inline-loader v-if="loading_count > 0"></inline-loader>
        <template v-else>
            <table class="table table-bordered" style="max-width: 700px;">
                <thead>
                    <tr class="table-dark">
                        <th class="text-center col-10" rowspan="2">ステータス</th>
                        <th class="text-center col-5" rowspan="2">件数</th>
                        <th class="text-center col-5" colspan="3">評価</th>
                    </tr>
                    <tr class="table-dark">
                        <th class="text-center col-5">見込</th>
                        <th class="text-center col-5">コナン</th>
                        <th class="text-center col-5">長期</th>
                    </tr>
                </thead>
                <tbody>

                    <template v-for="status_rank, index in status_ranks" :key="status_rank.status_label">
                        <tr>
                            <td :class="{'table-secondary': isLast(index)}" class="align-middle">{{ status_rank.status_label }}</td>
                            <td :class="[isLast(index) ? 'table-secondary' : 'table-light']" class="align-middle text-center">{{ $helper.number(status_rank.total_counts) }}</td>
                            <td :class="{'table-secondary': isLast(index)}" class="align-middle text-center">{{ $helper.number(status_rank.prospect_counts) }}</td>
                            <td :class="{'table-secondary': isLast(index)}" class="align-middle text-center">{{ $helper.number(status_rank.conan_counts) }}</td>
                            <td :class="{'table-secondary': isLast(index)}" class="align-middle text-center">{{ $helper.number(status_rank.protracted_counts) }}</td>
                        </tr>
                    </template>
                    <!-- /追客なし/他社落ち/自社運用/取得見送り/受注 は増え続けるため表示しない -->
                </tbody>
            </table>
        </template>
    </section>

</template>

<script>
import Department from '@/models/entities/department';
import InlineLoader from '@/components/tools/InlineLoader.vue';
import Standard from '@/models/enums/standard';
import FormSelectSearchMultiple from '@/components/forms/FormSelectSearchMultiple';

export default {
    name: 'ReportStatus',
    components: {
        InlineLoader,
        FormSelectSearchMultiple,
    },
    inject: [
        'startScreenLoading',
        'endScreenLoading',
        'showErrorMessage',
        'showMessage',
    ],
    data() {
        return {
            // ローディング
            loading_count: 0,

            // enum options
            Standard,

            // 検索フォーム
            options_department: [],
            exists_options_department: false,

            // 検索条件
            condition: {
                department_ids: [],
                standards: [],
            },

            // 表示データ
            status_ranks: [],
        }
    },
    mounted() {
        this.init();
    },
    methods: {
        init() {
            this.loading_count++;

            // 事業部マスタ取得
            this.$http.get('/master/department')
            .then(response => {
                for (let row of response.data) {
                    let department = new Department(row);
                    this.options_department.push({value: department.department_id, label: department.department_name});
                }

                //事業部未登録の場合
                if (this.options_department.length === 0) {
                    this.showErrorMessage('事業部が登録されていません。先に事業部を登録してください');
                    this.$router.push({name: 'MasterDepartment'});
                }
                if (this.$store.state.auth.department_id) {
                    // 自身の事業部を設定する
                    this.condition.department_ids.push(this.$store.state.auth.department_id);
                } else {
                    // 所属する事業部がなければ1つ目の事業部を設定
                    this.condition.department_ids.push(this.options_department[0].value);
                }

                this.search();
            })
            .finally(() => {
                //ローディング終了
                this.loading_count--;
            })
        },
        // 検索
        search() {
            // 検索条件を保存
            this.$store.commit('condition/setSearchCondition', {
                page: 'ReportStatus',
                condition: this.condition,
            });
            // アポまでの日数の件数リスト取得
            this.fetchStatusReport();
        },
        // アポまでの日数の件数リスト取得
        fetchStatusReport() {
            // ローディング開始
            this.loading_count++;
            // 表示データ初期化
            this.status_ranks.splice(0);

            this.$http.get('/report/status', {
                params: this.condition,
            })
            .then(response => {
                for (let row of response.data) {
                    this.status_ranks.push(row);
                }
            })
            .finally(() => {
                // ローディング終了
                this.loading_count--;
            })
        },
        isLast(index) {
            return index === this.status_ranks.length - 1;
        }
    }
}
</script>

<style scoped>

</style>
